<template>
  <div id="mainimage">
    <div class="bg">
      <div class="content01">
        <p class="title">
          <img
            src="@/assets/images/top/mainimage_logo.svg"
            alt="MOG Motor Owner GrandPrix"
          />
        </p>
      </div>
    </div>
  </div>
  <div id="choice">
    <div id="news">
      <div class="content02">
        <div class="wrap">
          <div class="news_content">
            <h3><span class="en">NEWS</span>ニュース</h3>
            <ul class="list">
              <li
                v-bind:class="diffDateShow(momentCal(val.date), dateToday)"
                v-for="val in infomation"
                :key="val"
              >
                <a href="#" class="txt-link" v-on:click="openModal(val.body)"
                  ><span class="time">{{ moment(val.date) }}</span
                  ><br class="hidden-md" /><span class="title">{{
                    val.title
                  }}</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade" v-show="isShow" @close="closeModal">
      <div>
        <div class="modal_inner modal_inner--01">
          <div class="modal_box">
            {{ infobody }}
            <button class="btn_modal_close_right" @click="closeModal()">
              <img
                src="../assets/images/parts/ico_close.svg"
                width=""
                height=""
                alt="閉じる"
              />
            </button>
          </div>
        </div>
        <div class="modal_outer" @click="closeModal()"></div>
      </div>
    </transition>

    <div class="bg content02">
      <div class="content01">
        <h2>エースモーターを見抜き、<br />豪華賞品をGET!</h2>
        <p class="txt01">
          MOGとは、新モーター導入から<br
            class="hidden-md"
          />翌年のモーター入れ替えまでの期間で、<br />
          自身が登録したモーターの総獲得モグーが<br
            class="hidden-md"
          />ポイント（モグー）になります。<br />
          そのポイントで競い合う、<br
            class="hidden-md"
          />モーター目利き力が試されるゲームです。<br />
        </p>
      </div>
    </div>
  </div>

  <div class="top_btn_box">
    <div class="content01">
      <ul>
        <li>
          <router-link :to="{ name: 'Registration' }" class="img-link"
            ><img src="@/assets/images/top/btn01.png" alt="新規登録"
          /></router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SelectMotor' }" class="img-link"
            ><img src="@/assets/images/top/btn02.png" alt="モーターを登録する"
          /></router-link>
        </li>
      </ul>
    </div>
  </div>

  <section id="rule">
    <h2 class="contents-h">
      <span class="img"
        ><img src="@/assets/images/top/rule_headimg.jpg" alt=""
      /></span>
      <span class="title"
        ><img
          src="@/assets/images/top/roule_title.png"
          alt="RULE MOGルール説明"
      /></span>
    </h2>
    <div class="roule_content content02">
      <div class="roule_block right" id="roule01">
        <div class="rb_content">
          <div class="txt_box">
            <h3>新規登録</h3>
            <p class="txt01">
              ニックネーム・メールアドレス・パスワードを登録するだけ！<br />
              <br />
              <router-link :to="{ name: 'Registration' }" class="img-link"
                ><img src="@/assets/images/top/btn03.png" alt=""
              /></router-link>
            </p>
          </div>
          <p class="img">
            <img src="@/assets/images/top/roule_img_01.jpg" alt="" />
          </p>
        </div>
      </div>
      <div class="roule_block left" id="roule02">
        <div class="rb_content">
          <p class="img">
            <img src="@/assets/images/top/roule_img_02.jpg" alt="" />
          </p>
          <div class="txt_box">
            <h3>モーター登録</h3>
            <p class="motor_info">
              モーター登録締切<br /><span>2024年10月25日</span>（金）まで
            </p>
            <p class="txt01">
              モーター登録画面から、<br
                class="hidden-md"
              />あなたのモーター目利き力を活かして、<br />
              性能の良いモーターを登録しよう！<br />
              ※最大６機まで登録できます<br />
              ※一度モーターを登録すると、変更はできません
            </p>
          </div>
        </div>
      </div>
      <div class="roule_block right" id="roule03">
        <div class="rb_content">
          <div class="txt_box">
            <h3>モグーを獲得</h3>
            <p class="txt01">
              登録したモーターの獲得賞金に応じて、モグーが加算！<br />
              年間を通じて、最も多くのモグーを獲得できた方が優勝です！<br />
              <br />
              <router-link :to="{ name: 'Rule' }" class="img-link"
                ><img src="@/assets/images/top/btn04.png" alt=""
              /></router-link>
            </p>
          </div>
          <p class="img">
            <img src="@/assets/images/top/roule_img_03.jpg" alt="" />
          </p>
        </div>
      </div>
    </div>
  </section>

  <div class="top_btn_box">
    <div class="content01">
      <ul>
        <li>
          <router-link :to="{ name: 'Registration' }" class="img-link"
            ><img src="@/assets/images/top/btn01.png" alt="新規登録"
          /></router-link>
        </li>
        <li>
          <router-link :to="{ name: 'SelectMotor' }" class="img-link"
            ><img src="@/assets/images/top/btn02.png" alt="モーターを登録する"
          /></router-link>
        </li>
      </ul>
    </div>
  </div>

  <section id="prize">
    <h2 class="contents-h">
      <span class="title"
        ><img src="@/assets/images/top/prize_title.png" alt="PRIZE 賞品紹介"
      /></span>
      <span class="img"
        ><img src="@/assets/images/top/prize_headimg.jpg" alt=""
      /></span>
    </h2>
    <div class="prize_box">
      <div class="pirze_content">
        <div class="prize_img">
          <div class="prize_block point">
            <p class="name">上位入賞者</p>
            <p class="img">
              <img src="@/assets/images/top/pirze_img_point.png" alt="" />
            </p>
            <p class="txt01">
              ランキング順位に合わせて、<br />総額77万ガマポをプレゼント!
            </p>
            <p class="txt02">
              <span class="sp_txt01">1位 150,000pt<br /></span>
              <span class="sp_txt02"
                >2位 100,000pt&emsp;3位 70,000pt<br
              /></span>
              4位 50,000pt&emsp;5位 30,000pt&emsp;6〜10位 10,000pt<br />
              11〜20位 7,000pt&emsp;21〜50位 5,000pt&emsp;<br />
              51〜100位 2,000pt
            </p>
          </div>
          <div class="prize_block quo">
            <p class="name">参加者</p>
            <p class="img">
              <img src="@/assets/images/top/pirze_img_quo.png" alt="" />
            </p>
            <p class="txt03">
              100位以降で下一桁が「7」の順位に<br />
              <span class="sub_txt_01"> （例：107位、567位、777位） </span>
              該当するMOGユーザーへBR蒲郡オリジナルQUOカード（500円分）をプレゼント！
              <span class="sub_txt_02"
                >※1497位で打ち切りとなります。<br />※QUOカード画像はイメージです。</span
              >
            </p>
          </div>
        </div>
        <!-- <div class="prize_text">
          <p>
            オーナーランキング上位3名様に<br
              class="hidden-md"
            />選手サイン入りプロペラをプレゼント！<br />
            また、抽選で30名様に<br
              class="hidden-md"
            />MOGオリジナルQUOカードをプレゼント！<br />
            ※選手は後日発表いたします。<br
              class="hidden-md"
            />※選手は指定できません。
          </p>
        </div> -->
      </div>
    </div>
    <div class="link_box">
      <p class="link_block" id="link01">
        <router-link :to="{ name: 'NominateList' }" class="txt-link">
          <span class="img"
            ><img src="@/assets/images/top/fbtn_img_01.png" alt=""
          /></span>
          <span class="txt_box">
            <span class="name">あの人の登録モーター</span>
            <span class="en">MOTOR</span>
          </span>
        </router-link>
      </p>
      <p class="link_block" id="link02">
        <router-link :to="{ name: 'RankingTopic' }" class="txt-link">
          <span class="img"
            ><img src="@/assets/images/top/fbtn_img_02.png" alt=""
          /></span>
          <span class="txt_box">
            <span class="name">ランキングTOPICS</span>
            <span class="en">RANKING</span>
          </span>
        </router-link>
      </p>
      <!-- <p class="link_block" id="link03">
        <a href="" class="txt-link">
          <span class="img"
            ><img src="@/assets/images/top/fbtn_img_03.png" alt=""
          /></span>
          <span class="txt_box">
            <span class="name">記者対抗企画</span>
            <span class="en">PLANNING</span>
          </span>
        </a>
      </p> -->
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Top_contents",
  props: {
    msg: String,
  },
  data() {
    return {
      isShow: false,
      infobody: "",
      dateToday: "",
    };
  },
  created: function () {},
  mounted() {
    this.$setInterval(() => {
      this.$store.dispatch("getInformation");
    }, 15000);
    this.dateToday = moment(new Date()).format("YYYY/MM/DD");
  },
  computed: {
    ...mapGetters({
      infomation: "getInformation",
    }),
  },
  methods: {
    openModal: function (val) {
      this.isShow = true;
      this.infobody = val;
    },
    closeModal() {
      this.isShow = false;
      this.infobody = "";
    },
    moment: function (date) {
      return moment(date).format("YYYY年MM月DD日");
    },
    momentCal: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    diffDate: function (news_date, today) {
      return moment(news_date).diff(today, "days");
    },
    diffDateShow: function (news_date, today) {
      if (moment(news_date).diff(today, "days") > -10) {
        return "new";
      }
      return "";
    },
  },
  beforeUnmount: function () {
    this.$clearAllIntervals();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_top.css" scoped></style>
<style lang="css" src="@/assets/css/style_parts.css" scoped></style>
