import { createApp, h } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { initialState } from "./store";
import Setinterval from "@/plugins/Setinterval";
import VueGtm from "vue-gtm";

const app = createApp({
  render: () => h(App),
  created() {
    localStorage.setItem("initialState", JSON.stringify(initialState));
  },
});
app.use(router);
app.use(store);
app.use(VueGtm, {
  id: "GTM-TMLRH7R",
});
// app.use(createMetaManager());
app.use(Setinterval);
app.mount("#app");
