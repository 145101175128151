<template>
  <header>
    <router-link to="/">
      <div class="title">
        <h1>MOG × BR蒲郡</h1>
      </div>
    </router-link>
    <div class="btn_menu">
      <div
        class="navToggle"
        v-on:click="menuON = !menuON"
        v-bind:class="{ active: menuON }"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <p v-on:click="menuON = !menuON" class="menu_txt">MENU</p>
    </div>
    <transition v-if="loginStatus" name="fade" v-show="menuON">
      <menuLogin />
    </transition>
    <transition v-else name="fade" v-show="menuON">
      <menuLogout />
    </transition>
  </header>
</template>

<script>
import menuLogin from "@/components/menuLogin.vue";
import menuLogout from "@/components/menuLogout.vue";
export default {
  name: "Header",
  components: {
    menuLogin,
    menuLogout,
  },
  data() {
    return {
      menuON: false,
      loginStatus: true,
    };
  },
  mounted: function () {
    const flg = this.$store.getters.getIsLogedIn;
    if (flg) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_header.css" scoped></style>
