<template>
  <div class="menu" id="logout_menu">
    <div class="menu_container">
      <div class="menu_content">
        <div class="account">
          <p class="img">
            <img src="../assets/images/common/damy_account_img.jpg" alt="" />
          </p>
          <div class="txt_box">
            <p class="message">こんにちは</p>
            <p class="name"><span>ゲスト</span>さん</p>
          </div>
        </div>
        <ul class="btn_box">
          <li>
            <router-link to="/regist" class="txt-link">新規登録</router-link>
          </li>
          <li>
            <router-link to="/login" class="txt-link">ログイン</router-link>
          </li>
        </ul>
        <ul class="link_list_01">
          <li>
            <router-link to="/" class="txt-link">MOG TOP</router-link>
          </li>
          <li>
            <router-link to="/nominate_list" class="txt-link"
              >あの人の登録モーター</router-link
            >
          </li>
          <li>
            <router-link to="/ranking_topic" class="txt-link"
              >ランキングTOPICS</router-link
            >
            <ul class="link_list_02">
              <li>
                <router-link to="/ranking_owner" class="txt-link"
                  >オーナーランキング</router-link
                >
              </li>
              <li>
                <router-link to="/ranking_motor" class="txt-link"
                  >モーターランキング</router-link
                >
              </li>
              <li>
                <router-link to="/ranking_last_year" class="txt-link"
                  >過去のオーナーランキング</router-link
                >
              </li>
            </ul>
          </li>
          <li class="visible_sp">
            <router-link to="/rule" class="txt-link">ルール</router-link>
          </li>
          <li class="visible_sp">
            <router-link to="/faq" class="txt-link">FAQ</router-link>
          </li>
          <li class="visible_sp">
            <router-link to="/terms_of_service" class="txt-link"
              >利用規約</router-link
            >
          </li>
          <!-- <li>
            <router-link to="#" class="txt-link">記者対抗企画</router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menuLogout",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_header.css" scoped></style>
