<template>
  <footer>
    <div class="content01">
      <div class="footer_content">
        <ul class="link_list">
          <li>
            <router-link :to="{ name: 'Home' }" class="txt-link"
              >MOG TOP</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'SelectMotor' }" class="txt-link"
              >モーター一覧</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'NominateList' }" class="txt-link"
              >あの人の登録モーター</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'RankingTopic' }" class="txt-link"
              >ランキングTOPICS</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Rule' }" class="txt-link"
              >ルール</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Faq' }" class="txt-link"
              >FAQ</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'TermsOfService' }" class="txt-link"
              >利用規約</router-link
            >
          </li>
          <!-- <li><a href="#" class="txt-link">記者対抗企画</a></li> -->
          <li v-if="loginStatus">
            <router-link to="/inquiry" class="txt-link"
              >お問い合わせ</router-link
            >
          </li>
        </ul>
        <div class="logo_box">
          <p class="logo">
            <router-link to="/">
              <img
                src="@/assets/images/common/footer_logo.svg"
                alt="MOG Motor Owner GrandPrix"
              />
            </router-link>
          </p>
          <p class="copyright">
            &copy; BOAT RACE GAMAGORI All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {
      loginStatus: true,
    };
  },
  mounted: function () {
    const flg = this.$store.getters.getIsLogedIn;
    if (flg) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_footer.css" scoped></style>
