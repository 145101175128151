<template>
  <Header />
  <Contents />
  <Footer />
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Contents from "@/components/Top.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  mounted: function () {
    const title = "MOG(モグー) ボートレース蒲郡";
    const description =
      "ボートレース蒲郡が主催するボートレースの新しい楽しみ方。エースモーターを選び、モグーを貯めて賞品をゲットしよう！";
    document.title = title;
    // document.querySelector("meta[property='og:url']").setAttribute('content', this.$route.fullpath)
    document
      .querySelector("meta[property='og:title']")
      .setAttribute("content", title);
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", description);
    document
      .querySelector("meta[property='og:description']")
      .setAttribute("content", description);
  },
  components: {
    Header,
    Contents,
    Footer,
  },
};
</script>
