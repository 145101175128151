<template>
  <div class="menu" id="login_menu">
    <div class="menu_container">
      <div class="menu_content">
        <div class="account">
          <p class="img">
            <img :src="info.icon ? info.icon : Icon[0].img" alt="" />
          </p>
          <div class="txt_box">
            <p class="message">こんにちは</p>
            <p class="name">
              <span>{{ info.nickname }}</span
              >さん
            </p>
          </div>
        </div>
        <hr />
        <ul class="link_list_01">
          <li>
            <router-link to="/" class="txt-link">MOG TOP</router-link>
          </li>
          <li>
            <router-link to="/mypage" class="txt-link">マイページ</router-link>
            <ul class="link_list_02">
              <li>
                <router-link to="/mypage/info" class="txt-link"
                  >基本情報</router-link
                >
              </li>
            </ul>
          </li>
          <li>
            <router-link to="/select_motor" class="txt-link"
              >モーター一覧</router-link
            >
          </li>
          <li>
            <router-link to="/nominate_list" class="txt-link"
              >あの人の登録モーター</router-link
            >
          </li>
          <li>
            <router-link to="/ranking_topic" class="txt-link"
              >ランキングTOPICS</router-link
            >
            <ul class="link_list_02">
              <li>
                <router-link to="/ranking_owner" class="txt-link"
                  >オーナーランキング</router-link
                >
              </li>
              <li>
                <router-link to="/ranking_motor" class="txt-link"
                  >モーターランキング</router-link
                >
              </li>
              <li>
                <router-link to="/ranking_last_year" class="txt-link"
                  >過去のオーナーランキング</router-link
                >
              </li>
            </ul>
          </li>
          <!-- <li>
            <router-link to="#" class="txt-link">記者対抗企画</router-link>
          </li> -->
          <li class="visible_sp">
            <router-link to="/rule" class="txt-link">ルール</router-link>
          </li>
          <li class="visible_sp">
            <router-link to="/faq" class="txt-link">FAQ</router-link>
          </li>
          <li class="visible_sp">
            <router-link to="/terms_of_service" class="txt-link"
              >利用規約</router-link
            >
          </li>
          <li>
            <router-link to="/inquiry" class="txt-link"
              >お問い合わせ</router-link
            >
          </li>
          <li>
            <a href="#" class="txt-link" v-on:click.prevent="logout()"
              >ログアウト</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "menuLogin",
  computed: {
    ...mapGetters({
      info: "getMemberInfo",
      Icon: "getIcon",
    }),
  },
  methods: {
    logout() {
      this.$store.commit("RESET_VUEX_STATE");
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
      this.$clearAllIntervals();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" src="@/assets/css/style_header.css" scoped></style>
