export default {
  install(app) {
    app.config.globalProperties.$intervals = [];
    app.config.globalProperties.$setInterval = (func, intervalMilliSec) => {
      const id = setInterval(() => {
        if (document.visibilityState === "visible") {
          func();
        }
      }, intervalMilliSec);
      app.config.globalProperties.$intervals.push(id);
      return id;
    };
    app.config.globalProperties.$clearInterval = (id) => {
      clearInterval(id);
      app.config.globalProperties.$intervals =
        app.config.globalProperties.$intervals.filter((i) => i !== id);
    };
    app.config.globalProperties.$clearAllIntervals = () => {
      app.config.globalProperties.$intervals.forEach(clearInterval);
      app.config.globalProperties.$intervals = [];
    };
  },
};
