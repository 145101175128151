/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store/index.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/inquiry",
    component: () => import("../views/Inquiry.vue"),
    children: [
      {
        path: "",
        name: "Inquiry",
        component: () => import("../components/Inquiry.vue"),
      },
      {
        path: "confirm",
        name: "InquiryConfirm",
        component: () => import("../components/InquiryConfirm.vue"),
      },
      {
        path: "thanks",
        name: "InquiryThanks",
        component: () => import("../components/InquiryThanks.vue"),
      },
    ],
  },
  {
    path: "/regist",
    name: "Registration",
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/remind_mail",
    component: () => import("../views/RemindMail.vue"),
    children: [
      {
        path: "",
        name: "RemindMail",
        component: () => import("../components/RemindMail.vue"),
      },
      {
        path: "thanks",
        name: "RemindMailThanks",
        component: () => import("../components/RemindMailThanks.vue"),
      },
    ],
  },
  {
    path: "/remind_pass",
    component: () => import("../views/RemindPass.vue"),
    children: [
      {
        path: "",
        name: "RemindPass",
        component: () => import("../components/RemindPass.vue"),
      },
      {
        path: "thanks",
        name: "RemindPassThanks",
        component: () => import("../components/RemindPassThanks.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/mypage",
    component: () => import("../views/Mypage.vue"),
    children: [
      {
        path: "",
        name: "mypageTop",
        component: () => import("../components/mypageTop.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "info",
        name: "mypageBasic",
        component: () => import("../components/mypageBasic.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/ranking_motor",
    name: "RankingMotor",
    component: () => import("../views/RankingMotor.vue"),
  },
  {
    path: "/nominate_dtl/:id",
    name: "NominateDtl",
    component: () => import("../views/NominateDtl.vue"),
    props: true,
  },
  {
    path: "/nominate_list",
    name: "NominateList",
    component: () => import("../views/NominateList.vue"),
  },
  {
    path: "/ranking_owner",
    name: "RankingOwner",
    component: () => import("../views/RankingOwner.vue"),
  },
  {
    path: "/ranking_last_year",
    name: "RankingLastYear",
    component: () => import("../views/RankingLastYear.vue"),
  },
  {
    path: "/ranking_owner_dtl/:id",
    name: "RankingOwnerDtl",
    component: () => import("../views/RankingOwnerDtl.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/ranking_topic",
    name: "RankingTopic",
    component: () => import("../views/RankingTopic.vue"),
  },
  {
    path: "/select_motor",
    name: "SelectMotor",
    component: () => import("../views/SelectMotor.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/motor_dtl/:id",
    name: "MotorDtl",
    component: () => import("../views/MotorDtl.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/rule",
    name: "Rule",
    component: () => import("../views/Rule.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/terms_of_service",
    name: "TermsOfService",
    component: () => import("../views/TermsOfService.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition;
  } else {
    return { top: 0 }
  }
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior
});

router.beforeEach((to) => {
  if(to.meta.requiresAuth){
    if(!store.state.token){
      return {
        path: "/login",
        // save the location we were at to come back later
        query: { redirect: to.fullPath },
      };
    }else{
      store.dispatch('tokenCheck',store.state.token)
    }
  }
});

export default router;
