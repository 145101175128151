<template>
  <div id="app">
    <router-view />
  </div>
</template>
<style>
@import "assets/css/common.css";
@import "assets/css/style_footer.css";
@import url("https://fonts.googleapis.com");
@import url("https://fonts.gstatic.com");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap");
</style>
